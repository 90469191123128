import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'


class RedisGroup extends React.Component {
  constructor(props) {
    super(props)
    this.redisNodes = this.props.nodes.filter((node) => { return node.replication_group_id == props.group.replication_group_id })
    this.avz = this.redisNodes.map((node) => { return node.preferred_availability_zone })
  }
  render() {
    return (
      <div>
        <div className="card">
          <div className="resources">
            {this.props.group.name}
            <span className="right_chevron">
              <a href={`#${this.props.group.name}`} data-toggle="collapse">
                <i className="fas fa-chevron-down" onClick={this.callMetrics}></i>
                {/* <i className="fas fa-chevron-down"></i> */}
              </a>
            </span>
          </div>
        </div>
        <div className="card collapse" id={this.props.group.name}>
          <div className="card-block">
            <div className="resources">
              <table className="table table-strip redis-nodes-table ">
                <thead className="thead">
                  <tr><th>Node Name</th><th>Status</th><th>Current Role</th><th>Zone</th></tr>

                </thead>
                <tbody>
                  {this.redisNodes.map((node) => { return <RedisNodes node={node} key={node.id} /> })}
                </tbody>
              </table>
              <table className="table table-sm table-borderless redis-table w-auto">
                <tbody>
                  <tr><td>Name:</td><td>{this.props.group.name}</td><td>Creation Time:</td><td>{this.redisNodes[0].creation_time}</td></tr>
                  <tr><td>Status:</td><td>{this.props.group.status}</td><td>Node Type:</td><td>{this.redisNodes[0].cache_node_type}</td></tr>
                  <tr><td>Engine:</td><td>{this.redisNodes[0].engine}</td><td>Shard:</td><td>{this.props.group.shard}</td></tr>
                  <tr><td>Compatibility:</td><td>{this.redisNodes[0].engine_version}</td><td>Number of nodes:</td><td>{this.props.group.nodes_num}</td></tr>
                  <tr><td>Availability zone:</td><td>{this.avz.join(', ')}</td></tr>
                  <tr><td>Primary endpoint:</td><td colSpan="3">{this.props.group.primary_endpoint}</td></tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

function RedisNodes({ node }) {
  return (
    <tr><td>{node.name}</td><td>{node.cache_cluster_status}</td><td>{node.current_role}</td><td>{node.preferred_availability_zone}</td></tr>

  )
}

export default RedisGroup