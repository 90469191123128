
import React from 'react'
import RedisMetrics from './RedisMetrics'
import ReactDOM from 'react-dom'


class RedisModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nodes: this.props.nodes };
    this.unmountModal = this.unmountModal.bind(this)
  }

  unmountModal() {
    let modal = { region: this.props.region, metric_name: this.props.metric_name, unit: this.props.unit, origin: 'modal' }
    this.props.setModal(modal)
  }

  render() {
    const params = { region: this.props.region, metric_name: this.props.metric_name, unit: this.props.unit, origin: this.props.origin, }

    return (
      <div className="modal fade" id="redis-modal" ref={node => (this.modalNode = node)}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {this.props.region}
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.unmountModal}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body card">
              {this.props.nodes && <RedisMetrics params={params} nodes={this.props.nodes} ref={node => (this.metricsNode = node)} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
};


export default RedisModal
