import React from 'react'
import RedisGroup from './RedisGroup'
import RedisMetrics from './RedisMetrics'
import RedisModal from './RedisModal'


class RedisIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = { modal: { region: 'us-east-1' } }
  }


  setModal = (modal) => {
    this.setState(state => ({ modal: modal }))
  };


  render() {
    return (
      <React.Fragment>
        {this.regionNames(this.props.cache_groups).map((groups) => {

          return <RegionHeader region={groups[0].region} groups={groups} nodes={this.props.cache_nodes} key={groups[0].region} setModal={this.setModal} />
        })}
        <RedisModal {...this.state.modal} setModal={this.setModal} />
      </React.Fragment>
    );
  }

  regionNames = (groups) => {
    let names = _.uniqBy(groups, 'region').map((x) => { return x.region }).reverse()

    return names.map(function (name) {
      return groups.filter(function (group) {
        if (group.region === name) {
          return group;
        }
      })
    })
  }
}



function RegionHeader(props) {
  let setModal = (modal) => {
    props.setModal(modal)
  };
  let cpuParams = { region: props.region, metric_name: 'CPUUtilization', unit: 'Percent', origin: 'mg', }
  let memParams = { region: props.region, metric_name: 'FreeableMemory', unit: 'Bytes', origin: 'mg', }
  return (
    <div>
      <div className="card text-light bg-dark">
        <div className="resources text-center">
          {props.region}
        </div>
      </div>
      {props.groups.map((group) => {
        return <RedisGroup group={group} nodes={props.nodes} key={group.id} />
      })
      }
      <div className="card mg">
        <div className="resources">
          CloudWatch Metrics
          <span className="right_chevron">
            <a href={`#${props.region}_metrics`} data-toggle="collapse">
              <i className="fas fa-chevron-down"></i>
              {/* <i className="fas fa-chevron-down"></i> */}
            </a>
          </span>
        </div>
      </div>
      <div className="card collapse mg" id={`${props.region}_metrics`}>

        <div className="card-block d-flex">
          <RedisMetrics params={cpuParams} nodes={props.nodes} setModal={setModal} />
          <RedisMetrics params={memParams} nodes={props.nodes} setModal={setModal} />
        </div>
      </div>
    </div>
  )
}


export default RedisIndex