import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

class DecommApps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requested_at: this.props.decomm.requested_at
        ? new Date(this.props.decomm.requested_at)
        : "",
      completed_at: this.props.decomm.completed_at
        ? new Date(this.props.decomm.completed_at)
        : "",
      note: this.props.decomm.note,
      edit: false
    };
    // if (this.state.id == 1) {
    //   console.log(moment(this.state.requested_at).format('llll'))
    // };
  }

  componentDidMount() {
    // decommDatePicker();
  }
  // onChangeRequestDate = (date) => { console.log(date); this.setState({ requested_at: date }) }
  // onChangeCompletedDate = (date) => { console.log(date); this.setState({ completed_at: date }) }
  handleChange = event => {
    this.setState({ note: event.target.value });
  };
  setReqTime = date => {
    this.setState({
      requested_at: date ? (date.getDate ? date : new Date()) : ""
    });
  };
  setComTime = date => {
    this.setState({
      completed_at: date ? (date.getDate ? date : new Date()) : ""
    });
  };
  edit = () => {
    this.setState({ edit: true });
  };
  cancel = () => {
    this.setState({
      requested_at: this.props.decomm.requested_at
        ? new Date(this.props.decomm.requested_at)
        : "",
      completed_at: this.props.decomm.completed_at
        ? new Date(this.props.decomm.completed_at)
        : "",
      note: this.props.decomm.note,
      edit: false
    });
  };
  update = () => {
    this.updateDecomm();
    $(`#decomm-${this.props.decomm.id}`).removeClass("flash-bg-success");
    $(`#decomm-${this.props.decomm.id}`).addClass("flash-bg-success");
    this.setState({ edit: false });
  };

  updateDecomm = () => {
    $.ajax({
      type: "PUT",
      beforeSend: function(xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      url: `/admin/archives/${this.props.decomm.id}`,
      dataType: "json",
      data: {
        note: this.state.note,
        requested_at: this.state.requested_at,
        completed_at: this.state.completed_at
      }
    })
      .done((data, status) => {
        console.log(data, status);
        // flash success color
      })
      .fail((data, status) => {
        console.log(data, status);
        // flash error
      });
  };
  render() {
    return (
      <React.Fragment>
        <td className="position-relative text-nowrap">
          {this.state.edit ? (
            <FaSave update={this.update} cancel={this.cancel} />
          ) : (
            <FaEdit edit={this.edit} />
          )}
          <span className="decomm-app-name">{this.props.app.name}</span>
        </td>
        <td className="text-nowrap">{this.props.app.acct_name}</td>
        <td>{this.props.app.subdomain}</td>
        <td>{this.props.status}</td>
        {this.state.edit ? (
          <EditNote note={this.state.note} handleChange={this.handleChange} />
        ) : (
          <ShowNote note={this.state.note} />
        )}
        {this.state.edit ? (
          <EditRequestedAt
            requested_at={this.state.requested_at}
            setReqTime={this.setReqTime}
          />
        ) : (
          <ShowRequestedAt requested_at={this.state.requested_at} />
        )}
        {this.state.edit ? (
          <EditCompletedAt
            completed_at={this.state.completed_at}
            setComTime={this.setComTime}
          />
        ) : (
          <ShowCompletedAt completed_at={this.state.completed_at} />
        )}
        <td>{this.props.app.region}</td>
      </React.Fragment>
    );
  }
}
function FaEdit(props) {
  return <i className="far fa-edit" onClick={props.edit}></i>;
}

function FaSave(props) {
  return (
    <React.Fragment>
      <i className="fas fa-times" title="cancel" onClick={props.cancel}></i>
      <i className="far fa-save" title="save" onClick={props.update}></i>
    </React.Fragment>
  );
}

function EditNote(props) {
  return (
    <td>
      <input
        type="text"
        value={props.note || ""}
        id=""
        onChange={props.handleChange}
      />
    </td>
  );
}
function ShowNote(props = { note: "" }) {
  return <td>{props.note}</td>;
}
function EditRequestedAt(props) {
  return (
    <td className="position-relative p-l-20">
      <span className="time-now" onClick={props.setReqTime}>
        now
      </span>
      <DateTimePicker onChange={props.setReqTime} value={props.requested_at} />
    </td>
  );
}
function ShowRequestedAt(props) {
  return (
    <td className="p-l-20">
      {props.requested_at
        ? moment(props.requested_at).format("MM-DD-YYYY, hh:mm:ss")
        : ""}
    </td>
  );
}
function EditCompletedAt(props) {
  return (
    <td className="position-relative p-l-20">
      <span className="time-now" onClick={props.setComTime}>
        now
      </span>
      <DateTimePicker onChange={props.setComTime} value={props.completed_at} />
    </td>
  );
}
function ShowCompletedAt(props) {
  return (
    <td className="p-l-20">
      {props.completed_at
        ? moment(props.completed_at).format("MM-DD-YYYY, hh:mm:ss")
        : ""}
    </td>
  );
}

export default DecommApps;
