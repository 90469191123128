import React from "react";
import $ from "jquery";
import moment from "moment";

class MaintenanceIssues extends React.Component {
  constructor(props) {
    super(props);
  }

  maintenanceAttr = [
    {
      msg:
        "Version conflict detected! - these apps may not be deployed correctly, or not yet updated.",
      name: "VersionFlagged",
      component: VersionFlagged,
      data: this.props.issues.version_flagged,
    },
    {
      msg: "Idling RDS's - these rds instances have no running apps.",
      name: "RdsEmpty",
      component: RdsEmpty,
      data: this.props.issues.rds_empty.filter(onlyUnique),
    },
    {
      msg:
        "Group unassigned - these apps has no group assigned to it. please edit and assign group.",
      name: "GroupEmpty",
      component: GroupEmpty,
      data: this.props.issues.group_empty,
    },
    {
      msg:
        "No secrect file, these apps has no inst secret file. check s3 locations.",
      name: "NoSecretFile",
      component: NoSecretFile,
      data: this.props.issues.no_secret_file,
    },
    {
      msg:
        "Url conflict - these apps has a different kast_subdomain than the kipu_url in secret file.",
      name: "UrlConflict",
      component: UrlConflict,
      data: this.props.issues.url_conflict,
    },
    {
      msg: "No Healthcheck - No health check found for these apps.",
      name: "NoHealthcheck",
      component: NoHealthcheck,
      data: this.props.issues.no_healthcheck,
    },
    {
      msg:
        "Duplicate Urls - these apps has the same subdomain, subdomain should be unique.",
      name: "DuplicateUrl",
      component: DuplicateUrl,
      data: this.props.issues.duplicate_url,
    },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="card text-light bg-dark mt-3">
          <div className="resources">Maintenance issues</div>
        </div>
        <div
          className={`card  ${moment().subtract("4", "hours") >
            moment(this.props.issues.fetch_time)
            ? "dash-alert blink"
            : "d-none"
            }`}
        >
          Extended Fetch Time detected! - check scheduled fetching for error.
        </div>
        {this.maintenanceAttr.map((attr) => {
          console.log(attr);
          return <Template attr={attr} key={attr.name} />;
        })}
      </React.Fragment>
    );
  }
}
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function Template({ attr: { msg, name, component, data } }) {
  console.log(data);
  const Component = component;
  if (!_.isEmpty(data)) {
    return (
      <React.Fragment>
        <div className="card ">
          <div>
            {msg}
            <span className="right_chevron float-right">
              <a href={`#${name}_card`} data-toggle="collapse">
                <i className="fas fa-chevron-down"></i>
              </a>
            </span>
          </div>
        </div>
        <div className="card collapse" id={`${name}_card`}>
          <div className="dash-grid-3">
            <Component data={data} />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

function NoSecretFile({ data }) {
  return data.map((app) => {
    return (
      <div key={app}>
        <a href={`/apps/name/${app}`} className="text-nowrap image-item">
          {app}
        </a>
      </div>
    );
  });
}

function UrlConflict({ data }) {
  return data.map((app) => {
    return (
      <div key={app}>
        <a href={`/apps/name/${app}`} className="text-nowrap image-item">
          {app}
        </a>
      </div>
    );
  });
}

function DuplicateUrl({ data }) {
  return data.map((app) => {
    return <div key={app}>{app}</div>;
  });
}

function NoHealthcheck({ data }) {
  return data.map((app) => {
    return (
      <div key={app}>
        <a href={`/apps/name/${app}`} className="text-nowrap image-item">
          {app}
        </a>
      </div>
    );
  });
}

function VersionFlagged({ data }) {
  return data.map((app) => {
    return (
      <div key={app}>
        <a href={`/apps/name/${app}`} className="text-nowrap image-item">
          {app}
        </a>
      </div>
    );
  });
}

function GroupEmpty({ data }) {
  return data.map((app) => {
    return (
      <div key={app.id}>
        <a href={`/apps/name/${app.name}`} className="text-nowrap image-item">
          {app.name}
        </a>
      </div>
    );
  });
}

function RdsEmpty({ data }) {
  return data.map((r) => {
    return (
      <div key={r}>
        <a href={`/rds/name/${r}`} className="text-nowrap image-item">
          {r}
        </a>
      </div>
    );
  });
}

export default MaintenanceIssues;
