import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

class RedisMetrics extends React.Component {
  constructor(props) {
    super(props);
    this.handleState();
    this.state = {
      params: {
        period: "300",
        timeLength: "3",
        unit: props.params.unit
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleState = () => {
    this.redisNodes = this.props.nodes.filter(node => {
      return (
        !!node.replication_group_id &&
        _.includes(node.preferred_availability_zone, this.props.params.region)
      );
    });
    this.cacheClusterIds = this.redisNodes.map(node => {
      return node.cache_cluster_id;
    });
  };
  handleChange() {
    this.setState({
      params: {
        period: this.periodNode.value,
        timeLength: this.timeLengthNode.value,
        unit: this.props.params.unit
      }
    });
  }

  componentDidMount() {
    this.callMetrics();
  }

  componentDidUpdate(prevProps, prevState) {
    // const { refetch, clearGraph } = this.props
    // refetch && clearGraph(this.metric)
    if (
      prevState.params == this.state.params &&
      JSON.stringify(prevProps.params) == JSON.stringify(this.props.params)
    ) {
      return;
    }
    this.handleState();
    this.callMetrics();
  }

  callMetrics = () => {
    $.ajax({
      type: "GET",
      url: "/redis/metrics",
      dataType: "json",
      data: {
        cacheClusterIds: this.cacheClusterIds,
        metric_name: this.props.params.metric_name,
        attr: this.state.params,
        region: this.props.params.region
      }
    })
      .done((metrics, status) => {
        console.log(metrics, status);
        // console.log(metrics, status)
        //prep metrics to a d3 usable data array
        let dataset = this.makeRedisDataSet(metrics.body);
        this.drawRedisMetrics(dataset, this.props.params.metric_name);
      })
      .fail((metrics, status) => {
        console.log(metrics, status);
        this.noMetrics();
      });
  };
  makeRedisDataSet = metrics => {
    //function to iterate through returned metrics, build an array for each individual statistics item in
    metrics.forEach(metric => {
      metric.forEach(met => {
        met.value = met.average;
        delete met.average;
      });
    });
    return metrics;
  };

  drawRedisMetrics = (data, title) => {
    // data = MG.convert.date(metrics, 'timestamp', '%Y-%m-%dT%H:%M:%S.%L%Z');

    if (data[0].length > 0) {
      for (var i = 0; i < data.length; i++) {
        data[i] = MG.convert.date(
          data[i],
          "timestamp",
          "%Y-%m-%dT%H:%M:%S.%L%Z"
        );
      }

      // let memService = `Service: ${service.name}, </br>Task count: ${service.task_count}, </br>Memory reservation: ${service.mem}`;
      // let cpuService = `Service: ${service.name}, </br>Task count: ${service.task_count}, </br>CPU unit: ${service.cpu}`;
      let description = this.cacheClusterIds.join(", ");
      // this.setState({ spinner: false })
      MG.data_graphic({
        title: title,
        description: description,
        data: data,
        width: 800,
        height: 300,
        target: document.getElementById(
          `mg_${this.props.params.region}_${this.props.params.metric_name}_${this.props.params.origin}`
        ),
        x_accessor: "timestamp",
        legend: this.cacheClusterIds,
        legend_target: `#legend_${this.props.params.region}_${this.props.params.metric_name}_${this.props.params.origin}`,
        animate_on_load: true
      });
    } else {
      this.noMetrics();
    }
  };

  noMetrics = () => {
    document.getElementById(
      `mg_${this.props.params.region}_${this.props.params.metric_name}_${this.props.params.origin}`
    ).innerHTML =
      '<div class="noMetrics"><div class="title">No metrics found</div></div>';
  };
  setModal = () => {
    let modal = {
      region: this.props.params.region,
      nodes: this.props.nodes,
      metric_name: this.props.params.metric_name,
      unit: this.props.params.unit,
      origin: "modal"
    };
    this.props.setModal(modal);
  };

  render() {
    return (
      <div>
        <div className="resources mg p-1">
          <div className="hover_control d-none">
            <i
              className="fas fa-expand"
              data-toggle="modal"
              data-target="#redis-modal"
              onClick={this.setModal}
            ></i>
            <i className="fas fa-sync" onClick={this.callMetrics}></i>
          </div>
          <div className="metric_controls">
            <span>Time</span>
            <select
              name="time_length"
              id="web_cpu_time_length"
              value={this.state.params.timeLength}
              ref={node => (this.timeLengthNode = node)}
              onChange={this.handleChange}
            >
              <option value=".5">30 min</option>
              <option value="1">1 hr</option>
              <option value="3">3 hrs</option>
              <option value="6">6 hrs</option>
              <option value="12">12 hrs</option>
              <option value="24">24 hrs</option>
              <option value="72">3 days</option>
            </select>
            <span>ago</span>
            <span className="space"></span>
            <span>period</span>
            <select
              name="period"
              id="web_cpu_period"
              value={this.state.params.period}
              ref={node => (this.periodNode = node)}
              onChange={this.handleChange}
            >
              <option value="30">30 sec</option>
              <option value="60">1 min</option>
              <option value="300">5 mins</option>
              <option value="600">10 mins</option>
              <option value="1800">30 mins</option>
              <option value="3600">1 hr</option>
            </select>
            <span className="space"></span>
            <span onClick={this.callMetrics}>
              <i className="fas fa-sync"></i>
            </span>
          </div>
          <div
            ref={el => (this.metric = el)}
            id={`mg_${this.props.params.region}_${this.props.params.metric_name}_${this.props.params.origin}`}
            className="metric_graph"
          >
            {/* {this.state.spinner && <Spinner />} */}
            <div
              id={`legend_${this.props.params.region}_${this.props.params.metric_name}_${this.props.params.origin}`}
              className="mg-legend"
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

function Spinner() {
  return (
    <div className="spinner">
      <i className="fas fa-spinner fa-pulse"></i>
    </div>
  );
}

export default RedisMetrics;
