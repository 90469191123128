import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { appendFileSync } from 'fs';
import { checkServerIdentity } from 'tls';

const appState = JSON.parse(localStorage.getItem('DataTables_apps_index'));
// const formatName = (name) => {
//   return name.toLowerCase().split(' ').join('_');
// }
class ColumnButton extends React.Component {
  constructor(props) {
    super(props)
    let show = this.showHideState();
    this.state = {
      btnColor: show ? 'btn-success' : 'btn-secondary',
      fontAwesome: show ? 'fa-check' : 'fa-ban',
      show: show ? 'true' : 'false',
    };
  }
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
  }
  showHideState = () => {
    if (appState) {
      return appState.columns[this.props.value].visible;
    } else {
      return true;
    };
  }
  changeColor = () => {
    this.setState({
      btnColor: this.state.btnColor === 'btn-success' ? 'btn-secondary' : 'btn-success',
      fontAwesome: this.state.fontAwesome == 'fa-check' ? 'fa-ban' : 'fa-check',
      show: this.state.show == 'true' ? 'false' : 'true',
    })
  }
  render() {
    return (
      <React.Fragment>
        <button className={`btn btn-sm mx-1 ${this.state.btnColor} col-btn`} onClick={this.changeColor} data-col-show={this.state.show} {...this.props}>
          <i className={`fas ${this.state.fontAwesome} mr-2`} />{this.props.children}
        </button>
      </React.Fragment>
    )
  }
}

// $("#columns-view-options :checked").each((i, e)=>{console.log(e.value)})
class ColumnsView extends React.Component {

  applyColumnsView = () => {
    let hideColumns = $('#columns-view-options button[data-col-show="false"]').map((i, btn) => { return btn.value }).toArray();
    let appColState = appTable.state().ColReorder;
    appTable.columns().visible(true); //this may not be necessary
    appTable.colReorder.reset(); //need to reset order first because data-col-show attr is hardset.
    appTable.columns(hideColumns).visible(false)
    appTable.colReorder.order(appColState);
  }

  render() {
    return (
      <React.Fragment>
        <div>View columns</div>
        <ColumnButton children='Name' value='1' />
        <ColumnButton children='Account' value='2' />
        <ColumnButton children='App Name' value='3' />
        <ColumnButton children='City' value='4' />
        <ColumnButton children='State' value='5' />
        <ColumnButton children='Country' value='6' />
        <ColumnButton children='Region' value='7' />
        <ColumnButton children='Subdomain' value='8' />
        <ColumnButton children='DB' value='9' />
        <ColumnButton children='Version' value='10' />
        <ColumnButton children='Group' value='11' />
        <ColumnButton children='Feeder' value='12' />
        <ColumnButton children='Created' value='13' />
        <ColumnButton children='Status' value='14' />
        <ColumnButton children='Cluster' value='15' />
        <span className="btn btn-primary btn-sm mr-1" id="apply" onClick={this.applyColumnsView}>apply</span>
        <span className="btn btn-info btn-sm" id="apply" onClick={viewAll}><i className='fas fa-undo' /> vew all</span>
      </React.Fragment>);
  }
}
// ColumnsView.defaultProps = {
//   name: 'David'
// }



export default ColumnsView


