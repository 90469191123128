import React from "react";

class DeployModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { versions: this.props.versions, app: this.props.app };
  }

  dataList = () => {
    return this.state.versions.map(v => {
      return `<option value="${v}"></option>`;
    });
  };

  render() {
    return (
      <div className="modal fade" id="DeployModal" tabindex="-1">
        <form
          className="form-inline"
          id="deploy-form"
          action=""
          accept-charset="UTF-8"
          method="get"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deploy-app-label">
                  Deployment Request
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="fas fa-spinner fa-pulse d_none"></i>
                <div id="deployModalBody">
                  <input name="utf8" type="hidden" value="✓" />
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="latest_image">
                          version
                        </span>
                      </div>
                      <input
                        type="text"
                        id="deployVersion"
                        className="form-control"
                        placeholder={`i.e. ${this.version}`}
                        aria-label="version"
                        required="required"
                        value="current version"
                        list="version_list"
                        onfocus="this.value=''"
                      />
                      <datalist id="version_list">{this.dataList()}</datalist>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="deploy_actin">
                          action
                        </span>
                      </div>
                      <select
                        type="text"
                        className="form-control"
                        aria-label="action"
                        id="deployAction"
                      >
                        <option value="restart">Restart</option>
                        <option value="restart_web">Restart WEB Service Only</option>
                        <option value="migrate">Migration only</option>
                        <option value="push_restart">Push | restart</option>
                        <option value="push_migrate_restart">
                          Push | migrate | restart
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick="return singleDeploy()"
                >
                  Deploy Now
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DeployModal;
